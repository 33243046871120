import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 10 cm de diàmetre, primer convex, després pla i deprimit al centre amb el marge corbat cap a dins i amb el temps es pot convertir en acanalat. La coloració és variable, de rosa, vermell i violaci a marró i cremós. Les làmines són atapeïdes, bifurcades prop del peu i es connecten amb les veïnes, són fràgils i de color crema que amb el temps s’enfosqueixen. El peu és més gruixut a la part inferior i rodó a la base, un poc rugós i de color blanc que amb el temps torna grisenc. Les espores són de color blanc en massa, ovoides i presenten berrugues més o menys atapeïdes i agudes, de 8-10 x 7-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      